import { NextPage } from 'next';
import { getStory } from 'storyblok/storyblok';
import useStoryblok from 'storyblok/storyblok-hook';
import { Homepage } from '@components/storyblok/Homepage/Homepage';
import { ISbStoryData } from '@storyblok/react';
import { getValidationTime } from '@lib/utils';
import EmailPopover from '@components/EmailPopover';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import imageURL from '../assets/images/background.png'

interface HomeProps {
  story: ISbStoryData;
}

const Home: NextPage<HomeProps> = ({ story }) => {
  const storyHook = useStoryblok(story);
  const [showNewsletter, setShowNewsletter] = useState(false);

  useEffect(() => {
    if (!showNewsletter) {
      setShowNewsletter(window.location.search.includes('newsletter'));
    }
  }, []);

  return (
    <>
      <Head>
      <meta property="og:image" content={`https://shop.sprenger.de/${imageURL.src}`} />
      </Head>
      <Homepage content={storyHook.content} />{' '}
      <EmailPopover
        showPopover={showNewsletter}
        klaviyoList="newsletter"
        titleKey="sale_popup_title"
        subtitleKey="sale_popup_subtitle"
        descriptionKey="sale_popup_description"
        emailLabel="sale_popup_email_label"
        ignoreBtnKey="sale_popup_anyway_btn"
        waitBtnKey="sale_popup_wait_btn"
        failureMessage="sale_popup_failure_message"
        successMessage="sale_popup_success_message"
      />
    </>
  );
};

export async function getStaticProps<T extends { preview: unknown; locale: string }>(context: T) {
  const { data } = await getStory('home', context.locale);

  return {
    props: {
      preview: context.preview || false,
      story: data?.story ?? false,
    },
    revalidate: getValidationTime(),
  };
}

export default Home;
