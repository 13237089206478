import React from 'react';
import DynamicComponent from '../DynamicComponent';
import { DynamicComponentBlok } from '@customTypes/storyblok-types';
import { MetaBlock } from '../MetaBlok';
import { storyblokEditable } from '@storyblok/react';

export const Homepage: React.FC<{
  content: any;
}> = ({ content }) => (
  <div
    className="w-full"
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(content)}
  >
    <MetaBlock
      content={content}
      defaults={{
        description: 'seo.description.home',
        meta_keywords: 'seo.meta_keywords.home',
        title: 'seo.title.home',
      }}
    />
    <div className="w-full bg-white space-y-wrapper-small -mb-14">
      {content?.header?.map((blok: DynamicComponentBlok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}

      <main className="space-y-wrapper-small">
        {content?.body?.map((blok: DynamicComponentBlok) => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}

        {content?.footer?.map((blok: DynamicComponentBlok) => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
      </main>
    </div>
  </div>
);
