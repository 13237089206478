import { isStg } from '@lib/utils';
import { ISbStoryData, useStoryblokState } from '@storyblok/react';

/**
 * the Storyblok hook to enable live updates
 * @param originalStory
 * @returns
 */
export default function useStoryblok(originalStory: ISbStoryData) {
  if (isStg()) {
    return useStoryblokState(originalStory) ?? originalStory;
  }
  return originalStory;
}
