import { useTranslate } from '@tolgee/react';
import Head from 'next/head';
import type { MetaBlockProps } from '@customTypes/storyblok-types';

export interface MetaBlockComponentProps {
  content?: any;
  defaults?: { title?: string; description?: string; meta_keywords?: string };
  isTesting?: boolean;
}

export const MetaBlock = ({ content, defaults, isTesting }: MetaBlockComponentProps) => {
  const { t } = useTranslate();

  if (!content?.meta && !defaults) {
    return <></>;
  }

  const _wrapHead = (children: JSX.Element) => (isTesting ? children : <Head>{children}</Head>);

  return _wrapHead(
    <>
      {(content?.meta?.find((blok: MetaBlockProps) => blok.component === 'title') ||
        defaults?.title) && (
        <title data-test="__meta-title">
          {content?.meta?.find((blok: MetaBlockProps) => blok.component === 'title')?.title ??
            t(defaults?.title ?? '')}
        </title>
      )}
      {(content?.meta?.find((blok: MetaBlockProps) => blok.component === 'description') ||
        defaults?.description) && (
        <meta
          data-test="__meta-description"
          name="description"
          content={
            content?.meta?.find((blok: MetaBlockProps) => blok.component === 'description')
              ?.description ?? t(defaults?.description ?? '')
          }
        />
      )}
      {(content?.meta?.find((blok: MetaBlockProps) => blok.component === 'meta_keywords') ||
        defaults?.meta_keywords) && (
        <meta
          data-test="__meta-keywords"
          name="keywords"
          content={
            content?.meta?.find((blok: MetaBlockProps) => blok.component === 'meta_keywords')
              ?.meta_keywords ?? t(defaults?.meta_keywords ?? '')
          }
        />
      )}
      {content?.meta?.find((blok: MetaBlockProps) => blok.component === 'should_not_index')
        ?.should_not_index && (
        <meta name="robots" content="noindex" data-test="__meta-should-index" />
      )}
    </>,
  );
};
