import { NextPage } from 'next';
import { T, useTranslate } from '@tolgee/react';
import { ButtonPrimary } from '../components/Button/Primary';
import { ButtonSecondary } from '../components/Button/Secondary';
import { useEffect, useState } from 'react';
import { FormFieldTextInput } from './Form/Field/TextInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { subscribe, type SubscriptionList } from '@lib/newsletter';
import { LoadingIndicator } from './LoadingIndicator';
import { Check, X } from './generated/icons';
import { useRouter } from 'next/router';

interface EmailPopoverProps {
  titleKey: string;
  showPopover: boolean;
  klaviyoList: SubscriptionList;
  successMessage?: string;
  failureMessage?: string;
  subtitleKey?: string;
  descriptionKey?: string;
  emailLabel?: string;
  ignoreBtnKey?: string;
  waitBtnKey?: string;
}

type Input = {
  email: string;
};

const EmailPopover: NextPage<EmailPopoverProps> = ({
  titleKey,
  showPopover,
  subtitleKey,
  klaviyoList,
  failureMessage,
  successMessage,
  descriptionKey,
  ignoreBtnKey,
  waitBtnKey,
  emailLabel,
}) => {
  const router = useRouter();
  const [show, setShow] = useState(showPopover);
  const [showForm, setShowForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const { t } = useTranslate();

  useEffect(() => {
    setShow(showPopover);
  }, [showPopover]);

  const blockScrolling = () => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'hidden';
    }
  };

  const unblockScrolling = () => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'auto';
    }
    router.push('/');
  };

  useEffect(() => {
    if (!show) {
      return;
    }
    blockScrolling();
    return () => unblockScrolling();
  }, [show]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Input>();
  const onSubmit: SubmitHandler<Input> = async (formData) => {
    setSuccess(false);
    setFailure(false);
    if (formData.email) {
      setLoading(true);
      const res = await subscribe(formData.email, klaviyoList, {});
      if (res.status === 200) {
        setSuccess(true);
      } else {
        setFailure(true);
      }
      setShowForm(false);
      setLoading(false);
    }
  };

  if (show) {
    return (
      <div className="bg-dark-tertiary items-center fixed top-0 left-0 right-0 bottom-0 z-[9999] flex justify-center">
        <div className="bg-white p-10 rounded-xl shadow-l2 flex flex-col max-w-[30rem]">
          <p className="text-h-24 uppercase mb-2">
            <T keyName={titleKey} />
          </p>
          <p className="text-h-14 uppercase mb-1">
            {subtitleKey ? <T keyName={subtitleKey} /> : null}
          </p>
          <p className="text-p-14 mb-6">{descriptionKey ? <T keyName={descriptionKey} /> : null}</p>

          {showForm && (
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative">
                {emailLabel && (
                  <FormFieldTextInput
                    className="mt-6 md:mt-0"
                    label={t(emailLabel)}
                    type="email"
                    name="email"
                    formRegister={register}
                    formWatch={watch}
                    errors={errors}
                    required
                  />
                )}

                {loading && (
                  <div className="absolute top-0 right-0">
                    <LoadingIndicator className="p-3" />
                  </div>
                )}
              </div>
              {waitBtnKey && !success && (
                <ButtonPrimary className="my-4 w-full" text={t(waitBtnKey)} type="submit" />
              )}
            </form>
          )}
          {ignoreBtnKey && !success && (
            <ButtonSecondary
              className="w-full"
              onClick={() => {
                setShow(false);
                unblockScrolling();
              }}
              text={t(ignoreBtnKey)}
            />
          )}
          {success && (
            <p className="text-dark-secondary font-bold text-h-16 w-full justify-center mb-2 flex gap-2">
              <Check className="text-special-success w-6 h-6" />
              {successMessage ? <T keyName={successMessage} /> : null}
            </p>
          )}
          {failure && (
            <p className="text-dark-secondary font-bold text-h-16 w-full justify-center mt-2 flex gap-2">
              <X className="text-special-danger w-6 h-6" />
              {failureMessage ? <T keyName={failureMessage} /> : null}
            </p>
          )}
          {ignoreBtnKey && success && (
            <ButtonSecondary
              onClick={() => {
                setShow(false);
                unblockScrolling();
              }}
              text={t(ignoreBtnKey)}
            />
          )}
        </div>
      </div>
    );
  }
  return <></>;
};
export default EmailPopover;
